@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,900&display=swap");
body {
  /* background: #fff; */
  font-size: 14px;
  color: #716e6e;
  font-family: "Raleway", sans-serif !important;
  background: url(https://cityfurnituretestrest.com/images/bg.png) no-repeat
    center center fixed !important;
  background-size: cover !important;
  font-weight: 300;
  line-height: 22px;
  padding-bottom: 100px;
  min-height: 100%;
  position: relative;
  margin: 0;
}
p.Survey-pragph{
  font-size: smaller;
  color: #000;
}

.carousel-inner>.item>a>img, .carousel-inner>.item>img, .img-responsive, .thumbnail a>img, .thumbnail>img {
  display: block;
  max-width: 100%;
  height: auto;
}
/* Dynamic Css Start  */

.white-box {
  border: 4px solid #f68d2d;
}
.footer {
  background-color: rgb(35, 31, 32);
}
input.sub-btn:hover {
  background: #f68d2d !important;
  border: 1px solid #f68d2d !important;
}
.white-box h1 {
  color: #cb2330;
}

/* Dynamic Css End  */

html {
  height: 100%;
  box-sizing: border-box;
}

form#signin {
  margin-bottom: 0px;
}
.white-box.thank {
  text-align: center;
}
.reward label {
  min-height: 64px !important;
}
.white-box-n h1 {
  font-size: 41px !important;
  font-weight: 600 !important;
  text-align: center;
  font-style: italic;
  margin-bottom: 27px;
  color: #000 !important;
}
.white-box-n h2 {
  color: red !important;
  font-weight: bold !important;
  font-size: 16px !important;
  text-align: center !important;
}
.white-box-n img {
  margin-bottom: 20px;
}
.white-box-n {
  text-align: center;
}
.white-box-n button:hover {
  background: #16419c;
  border: 1px solid #223d99;
}
.white-box-n p {
  color: red;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
}
.white-box-n button {
  background: #00a8e2;
  border: 1px solid #00a8e2;
  outline: none;
  color: #fff;
  padding: 6px 40px;
  font-weight: 700;
  font-size: 16px;
  text-transform: uppercase;
  border-radius: 20px;
}
.reward img {
  width: 100%;
}
i.zmdi.zmdi-assignment-check.circle {
  width: 120px;
  height: 120px;
  font-size: 52px;
  border: 2px solid #04b954;
  border-radius: 50%;
  padding: 30px;
  margin: 40px auto 30px;
  color: #04b954;
  margin: 40px auto;
  display: block;
}
.mt-2 {
  margin-top: 20px;
}
input,
submit,
button {
  box-shadow: none !important;
}
a {
  color: #000;
  text-decoration: none;
}
a:hover {
  color: #000;
  text-decoration: underline;
}
ul.points li:first-child {
  border: none;
}
h1 {
  color: #000;
  font-family: "Raleway", sans-serif;
}
h2,
h3,
h4,
h5,
h6 {
  color: #000;
  font-family: "Raleway", sans-serif;
}
.glyphicon-search:before {
  content: "\e003";
  font-family: "Glyphicons Halflings";
}
select {
  outline: none !important;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-top: 0;
  padding: 0px;
  margin-right: 0;
  margin-bottom: 10px;
  margin-left: 0;
}
p {
  line-height: 20px;
  color: #000;
}
p:last-child {
  margin-bottom: 0px;
}
h1 {
  font-size: 30px;
}

h2 {
  font-size: 26px;
}
h3 {
  font-size: 24px;
}
h4 {
  font-size: 20px;
}
h5 {
  font-size: 18px;
}
h6 {
  font-size: 16px;
}
:before,
:after {
  font-family: Material-Design-Iconic-Font;
}
p.note {
  background: #f68d2d;
  padding: 14px;
  color: #fff;
}
ul.info-data {
  padding: 0px;
  list-style: none;
  margin: 10px auto;
  display: inline-block;
}
ul.info-data li {
  border: 1px solid #ccc;
  color: #212121;
  margin-top: -1px;
  padding: 4px 52px;
}
.clear:after {
  content: "";
  width: 100%;
  clear: both;
  display: table;
}
.check-boexs {
  /* margin-right: 15px; */

  float: left;
  margin-bottom: 20px;
  width: 24.3% !important;
}

.logo img {
  margin: 0px auto;
  width: 360px;
}

.white-box {
  background: rgba(255, 255, 255, 0.85);
  padding: 30px 30px;
  border-width: 4px 0px;
  /* border: 4px solid #cb2330; */
}
.white-box h1 {
  color: #f68d2d;
  font-size: 27px;
  font-weight: 700;
  text-align: left;
  margin-bottom: 20px;
}

.white-box h2 {
  color: #3e3935;
  font-size: 17px;
  font-weight: 600;
}
ul.points {
  padding-left: 0px;
  width: 100%;
}
ul.points li {
  list-style: none;
  font-size: 15px;
  color: #313131;
  padding: 17px 0px;
  font-weight: 400;
  border-bottom: 1px solid #e4e4e4;
}
ul.points img {
  width: 55px;
}
a {
  color: #000 !important;
  text-decoration: none !important;
}
a:hover {
  text-decoration: underline !important;
}
ul.points li a {
  color: #f68d2d !important;
  text-decoration: none !important;
  /* font-weight: 500; */
}
ul.points li a:hover {
  color: #697db9 !important;
}

ul.points li:last-child {
  border-bottom: 0px;
}
form.form-outer label {
  width: 100%;
  /* text-transform: uppercase; */
  text-transform: inherit;
}
form.form-outer .removing label {
  font-weight: 700;
}
form.form-outer label span.carddetail {
  display: block;
}
.red_point {
  color: #f00;
}
.error_message_forimage {
  width: auto !important;
  padding: 0 !important;
  float: left;
}
form.form-outer input[type="text"],
form.form-outer input[type="email"],
form.form-outer input[type="tel"],
form.form-outer select {
  background: #ffffff00;
  width: 100%;

  margin-bottom: 10px;
  outline: none;
  padding-left: 20px;
  border-radius: 30px;
  height: 46px;
  border: 1px solid #3e3935;
}
.input-outer1 {
  position: relative;
  margin: 0px 0px;
}
.input-outer {
  position: relative;
  margin: 20px 0px;
}
/* form.form-outer button {
  position: absolute;
  outline: none;
  right: 0%;
  border-radius: 30px;
  outline: none;
  height: 46px;
  width: 110px;
  background: #231f20;
  border: 1px solid #231f20;
  color: #fff;
  top: 37px;
} */
input.sub-btn {
  display: flex;
  justify-content: center;
  color: #fff;
  padding: 10px 100px;
  border-radius: 30px;
  top: 37px;
  border: 1px solid #3e3935;
  background: #3e3935 !important;
  text-align: center;
  margin: 0 auto;
  margin-top: 20px;
}
input.sub-btn1 {
  background: #3e3935 !important;
  outline: none;
  color: #fff;
  padding: 7px 15px;
  border: 1px solid #3e3935 !important;
  border-radius: 50px;
}
input.sub-btn1:hover {
  background: #f68d2d !important;;
  border: 1px solid #f68d2d !important;
}

p.logo-text {
  font-size: 45px;
  line-height: 1.4;
  font-weight: 700;
  color: #3e3934;

  margin-top: 13px;
}

p.error {
  color: #e00d0d !important;
  border-radius: 30px;
  padding: 0px 0px !important;
  display: inline-block;
}
/* form.form-outer button:hover {
  background: #cb2330;
  border: 1px solid #cb2330;
} */
.footer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: center;
}
button#pop {
  border-color: #fff0;
}

div#inlineContent img {
  width: 300px;
}
div#ob_content p {
  text-align: center;
}
.footer p {
  color: #fff;
  padding: 15px 0px;
}
.white-box.form h2 {
  border: 1px solid #ccc;
  margin-top: 25px;
  padding: 10px 0px;
  border-width: 1px 0px 1px 0px;
}
form.form-outer.register label {
  text-transform: initial !important;
  font-weight: 400;
  font-size: 15px;
}
form.form-outer.register label span {
  color: #f00;
  font-size: 23px;
}
.phone-outer input {
  width: 30% !important;
}
input#choicekw0 {
  width: 10%;
  display: block;
  margin: 0 auto;
}
input#choicekw1 {
  width: 10%;
  display: block;
  margin: 0 auto;
}
a#userinfo {
  color: #4040bb !important;
  text-decoration: underline !important;
  outline: none !important;
}
input#choicekw2 {
  width: 10%;
  display: block;
  margin: 0 auto;
}
form.form-outer.register select {
  background: #ffffff00;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  outline: none;
  padding-left: 20px;
  border-radius: 30px;
  height: 46px;
  border: 1px solid #231f20;
}
input#choicekw3 {
  width: 10%;
  display: block;
  margin: 0 auto;
}
.reward input[type="radio"] {
  width: 100%;
  outline: none;
  margin-bottom: 2px;
}
.reward1 img {
  width: 120px;
}
.reward1 input[type="radio"] {
  width: 100%;
  outline: none;
  margin-bottom: 2px;
}
.reward1 {
  max-height: 310px;
  text-align: center;
  border: 1px solid #ccc;
  padding: 10px;
  background: #fff;
  margin-bottom: 10px;
  height: 200px;
}
input.submit-btn:hover {
  background: #cb2330;
  border: 1px solid #cb2330;
}
input.submit-btn {
  background: #3e3935;
  outline: none;
  color: #fff;
  padding: 7px 15px;
  border: 1px solid #3e3935;
  border-radius: 50px;
}
.reward {
  /* max-height: 160px; */
  text-align: center;
  /* overflow-x: hidden; */
  /* overflow-y: auto; */
  border: 1px solid #ccc;
  padding: 10px;
  background: #fff;
  margin-bottom: 10px;
  /* height: 295px; */
}
.reward_cert {
  /* max-height: 160px; */
  text-align: center;
  /* overflow-x: hidden; */
  /* overflow-y: auto; */
  /* border: 1px solid #ccc; */
  padding: 10px;
  /* background: #fff; */
  margin-bottom: 10px;
  /* height: 295px; */
}
form.form-outer.register button {
  position: static !important;
  border-radius: 30px;
  height: 46px;
  width: 110px;
  background: #231f20;
  border: 1px solid #211d1e;
  color: #fff;
  margin-top: 31px;
  float: right;
}
/* .fade.modal.show {
  background-color: #33333387
} */
img#popImg {
  width: 300px;
  align-items: center;
  margin: auto;
}
form.form-outer.register button:hover {
  background: #cb2330;
  border: 1px solid #cb2330;
}
.modelContent {
  margin-top: 20% !important;
}

@media (min-width: 576px){
  div#modelContent {
    max-width: 889px !important;
    margin: 1.75rem auto !important;
}
}
@media (min-width: 1200px) and (max-width: 1910px) {
  div#modelContent {
    max-width: 780px !important;
    margin: 1.75rem auto !important;
    /* margin-left: 226px !important; */
    /* margin-left: 395px !important; */
}
}

@media (min-width: 992px) and (max-width: 1199px)  {
  div#modelContent {
    max-width: 500px !important;
    margin: 1.75rem auto !important;
}
}
@media (min-width: 768px) and (max-width: 991px) {
  div#modelContent {
    max-width: 360px !important;
    margin: 1.75rem auto !important;
}
}
@media (min-width: 320px) and (max-width: 767px) {
  div#modelContent {
    max-width: 270px !important;
    margin: 1.75rem auto !important;
    margin-left: 36px auto!important;
}
img#popImg {
  width: 200px;
  align-items: center;
  margin: auto;
}
}
@media (min-width: 150px) and (max-width: 321px) {
  div#modelContent {
    max-width: 190px !important;
    /* margin: 1.75rem auto !important; */
    margin-left: 37px !important;
  }
  img#popImg {
    width: 150px;
    align-items: center;
    margin: auto;
  }
}
/*Responsive table*/
/*======
Media Queries
=======*/
/*========================*/
@media (min-width: 1200px) and (max-width: 1910px) {
  /* .container-fluid{width:60%;} */
}

@media (min-width: 992px) and (max-width: 1199px) {
  .container-fluid {
    width: 90%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* .logo img {
    margin: 20px auto !important;
    width: 360px;
  } */
  .reward_cert img{
    width: 150px;
    display: block;
    margin: 0 auto;
    margin-top: 5px;
    
  }

  p.logo-text {
    float: right;
    font-size: 45px;
    line-height: 1.4;
    font-weight: 700;
    color: #3e3934;

    margin-top: 13px;
  }
  ul.points li img {
    margin-bottom: 10px;
  }
  .container-fluid {
    width: 90%;
  }
  .check-boexs {
    /* margin-right: 15px; */
    float: none;
    margin-bottom: 20px;
    width: 100% !important;
  }
  .reward img {
    width: 150px;
    display: block;
    margin: 0 auto;
    margin-top: 5px;
    
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .white-box {
    text-align: center;
  }
  .reward_cert img {
    width: 100px;
    display: block;
    margin: 0 auto;
    margin-top: 5px;
  }
  /* .logo img {
    margin: 20px auto !important;
    width: 270px;
    float: none;
  } */
  p.logo-text {
    text-align: center;
    font-size: 35px;
    line-height: 1.3;
    font-weight: 700;
    color: #3e3934;
    margin-top: 13px;
    margin-bottom: 30px;
  }
  .phone-outer input {
    width: 28.6% !important;
  }
  ul.points li img {
    margin-bottom: 10px;
  }
  .container-fluid {
    width: 90%;
  }
  .check-boexs {
    /* margin-right: 15px; */
    float: none;
    margin-bottom: 20px;
    width: 100% !important;
  }
  .reward img {
    width: 150px;
    display: block;
    margin: 0 auto;
    margin-top: 5px;
  }
  .modal-content {
    width: 94% !important;
  }
}
@media (min-width: 320px) and (max-width: 400px) {
  /* .logo img {
    margin: 20px auto !important;
    width: 360px;
  } */
  p.logo-text {
    text-align: center;
    font-size: 45px;
    line-height: 1.4;
    font-weight: 700;
    color: #3e3934;

    margin-top: 13px;
  }
  .phone-outer input {
    width: 28.6% !important;
  }
  .check-boexs {
    /* margin-right: 15px; */
    float: none;
    margin-bottom: 20px;
    width: 100% !important;
  }
  .reward img {
    width: 150px;
    display: block;
    margin: 0 auto;
    margin-top: 5px;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  /* .logo img {
    margin: 20px auto !important;
    width: 360px;
  } */
  p.logo-text {
    text-align: center;
    font-size: 45px;
    line-height: 1.4;
    font-weight: 700;
    color: #3e3934;

    margin-top: 13px;
  }
  .phone-outer input {
    width: 28.6% !important;
  }
  .check-boexs {
    /* margin-right: 15px; */
    float: none;
    margin-bottom: 20px;
    width: 100% !important;
  }
  .reward img {
    width: 150px;
    display: block;
    margin: 0 auto;
    margin-top: 5px;
  }
}

@media (min-width: 25px) {
.col-md-11 {
    flex: 0 0 auto;
    width: 10px;
}
}
.logo {
  padding: 15px 0;
  background: #ffffffd9;
  margin: 20px auto;
}
h1 {
  text-align: center;
}

/* .white-box {
  width: 60%;
  margin: 0 auto;
} */
.phonenumber {
  font-size: 14px;
  font-family: Arial;
}
.recap {
  width: 304px;
  height: 78px;
}
.styling {
  width: 250px;
  height: 40px;
  border: 1px solid rgb(193, 193, 193);
  margin: 10px 25px;
  padding: 0px;
  resize: none;
  display: none;
}
i.zmdi.zmdi-assignment-check.circle {
  width: 120px;
  height: 120px;
  font-size: 52px;
  border: 2px solid #04b954;
  border-radius: 50%;
  padding: 30px;
  margin: 40px auto 30px;
  color: #04b954;
  margin: 40px auto;
  display: block;
}
.errorMsg1 {
  color: #e00d0d !important;
  border-radius: 30px;
  padding: 0px 0px !important;
  text-transform: none;
  font-weight: 400;
  /* margin-left: 60px;
  margin-bottom: 21px;
  margin-top: -20px; */
}
.errorMsg {
  color: #e00d0d !important;
  border-radius: 30px;
  padding: 0px 0px !important;
  text-transform: none;
  font-weight: 400;
}

input#myCheck {
  display: inline-block;
  width: auto;
}
svg {
  width: 120px;
  height: 120px;
  font-size: 52px;
  border: 2px solid #04b954;
  border-radius: 50%;
  padding: 30px;
  margin: 40px auto 30px;
  color: #04b954;
  margin: 40px auto;
  display: block;
}
ul.points li {
  list-style: none;
  font-size: 15px;
  color: #313131 !important;
  padding: 17px 0px;
  font-weight: 400;
  border-bottom: 1px solid #e4e4e4;
}

@media (min-width: 1200px) and (max-width: 1910px) {
  .container-fluid {
    width: 100%;
  }
}

b,
strong {
  font-weight: 700;
}
li.removing {
  border-bottom: 1px solid #fffafa !important;
}
label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 500;
}
@media (min-width: 320px) and (max-width: 767px) {
  img.card {
    margin: 0 auto !important;
  }
  .container-fluid {
    width: 90% !important;
  }
  .white-box {
    width: 100%;
    text-align: center;
  }
  .white-box h1 {
    text-align: center;
    font-size: 22px;
  }
  input#submit {
    margin-left: auto !important;
    margin-right: auto;
  }
  
}

@media (min-width: 768px) and (max-width: 991px) {
  .container-fluid {
    width: 90% !important;
  }
  .white-box {
    width: 100%;
  }
  
}

@media (min-width: 768px) {
  .col-md-11 {
    flex: 0 0 auto;
    width: -webkit-fill-available;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .white-box {
    width: 100%;
  }
}
@media (min-width: 992px) {
  .col-md-11 {
    width: 91.66666667% !important;
  }
}
@media (min-width: 1911px) and (max-width: 5000px) {
  .container-fluid {
    width: 900px !important;
  }
  .white-box {
    width: 100%;
  }
}
@media (min-width: 320px) and (max-width: 480px) {
  img.card {
    margin: 0 auto !important;
  }
  /* .logo img {
    margin: 20px auto !important;
    width: 260px;
  } */
  .container-fluid {
    width: 90% !important;
  }
}
label.formsub {
  max-width: 100%;
  font-size: 15px;
  margin-bottom: 5px;
  font-weight: 400;
  text-transform: unset !important;
}

.box {
  padding: 15px;
  margin: 15px;
  background-color: #00a8e2;
}

form.form-outer.register label {
  text-transform: initial !important;
  font-weight: 400;
  font-size: 15px;
}
input[type="checkbox"],
input[type="radio"] {
  margin: 4px 6px 0 !important;
  margin-top: 1px \9;
  line-height: normal;
}
.reward input[type="radio"] {
  margin: 0px !important;
}
.imgcolor {
  margin-top: 10px;
  /* box-shadow: 0 10px 28px rgb(0 0 0 / 0%), 0 10px 10px rgb(0 0 0 / 11%); */
  width: 100%;
  border-radius: 6px;
  margin-right: 8px;
}
input.submit-btn {
  background: #3e3935;
  outline: none;
  color: #fff;
  padding: 7px 15px;
  border: 1px solid #3e3935;
  border-radius: 50px;
}
@media (min-width: 320px) and (max-width: 575px) {
  .check-boexs {
    width: 100%;
    text-align: center;
  }
  /* img.imgcolor.img-responsive {
    width: auto;
    margin: 5px auto;
  } */
  /* .modal-dialog {
    max-width: 600px !important;
    margin: 30px auto !important;
    margin-right: 31% !important;
} */
}

@media (min-width: 1200px) and (max-width: 1910px) {
  .container-fluid {
    width: 60% !important;
  }
}

.header_lower {
  text-align: center;
  font-size: 22px;
  color: #fff !important;
  margin-bottom: 22px;
  width: 100% !important;
  background-color: #3e3936;
  padding: 8px;
  margin: 0 auto;
}
.modal {
  background-color: #0000003d !important;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
  /* background-color: #5d5f94; */
  background-color: #3e3935 !important;
  color: #fff;
}
button#btn1 {
  margin-right: 45%;
  color: #fff !important;
  border: 1px solid #3e3935;
  border-radius: 20px;
  outline: none !important;
  background-color: #3e3935 !important;
}
:before,
:after {
  font-family: Material-Design-Iconic-Font;
}
ul.terms-list li:before {
  content: "\f2fb";
  position: absolute;
  left: 12px;
  /* color: #5d5f94; */
  color: #3e3936;
}

ul.terms-list {
  list-style: none;
  font-size: 15px;
}
.terms-list1 {
  list-style: none;

} 
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 115% !important;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
  font-family: "Raleway", sans-serif;
}
button#pop {
  border: #fff;
  background-color: #fff0;
  color: #4040bb;
  text-decoration: underline;
  margin-left: -7px !important;
}
button#pop:hover {
  color: #0e66e9;
}
label.agree.label div {
  display: inline;
}
/* button.close {
  background-color: #424586;
  border: none;
  color: #fff;
  font-weight: bold;
} */
button#pop-close1 {
  /* background-color: #424586 !important; */
  background-color: #3e3935 !important; 
  border: 2px solid #fff !important;
  border-radius: 50% !important;
  font-weight: bold !important;
  font-size: 1.5rem;
  margin-left: -20px;
  margin-top: -21px;
  padding: 0;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
button#pop-close {
  /* background-color: #424586 !important; */
  background-color: #736d69 !important;
  border: none !important;
  border-radius: 50% !important;
  font-weight: bold !important;
  font-size: 1.5rem;
  padding: 0;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
button#btn1:hover {
  background: #f68d2d !important;
  outline: none !important;
  border: 1px solid #f68d2d !important;
}
.modal-content {
  box-shadow: 0 5px 15px rgb(0 0 0 / 70%);
  /* margin-top: 22% !important; */
}
div#card-section {
  margin-top: 4%;
}

.model-body {
  padding-right: 36px;
  padding-bottom: 15px;
  padding-top: 15px;
}
.modal-footer {
  width: 571px;
  height: 67px;
}
li {
  line-height: 22px;
}
.llist {
  list-style: none;
  text-align: center;
}
#note{
  text-align: center;
}
.modal-header h4 {
  color: #fff;
  font-size: 20px;
}
#header{
  border-bottom: none !important;
  background-color: #fff !important;
  
}
button#closeUp {
    margin-left: -81px;
    margin-top: -55px;
}
.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}
form.form-outer.register label {
  text-transform: initial !important;
  font-weight: 400;
  font-size: 15px;
}
label.heading1 {
  display: inline !important;
}
input.submit-btn.survey {
  background: #3e3935;
  outline: none;
  color: #fff;
  padding: 7px 15px !important;
  border: 1px solid #3e3935;
  border-radius: 50px;
}
input.submit-btn.survey:hover {
  background: #f68d2d;
  border: 1px solid #f68d2d;
}

label.inline {
  display: inline;
  width: auto !important;
}
label.heading1.ml-5 {
  margin-left: 50px;
}
label.heading1.ml-3 {
  margin-left: 80px;
}
#card_img {
  text-align: center;
}
a.btn.btn-md.btn-primary {
  position: static !important;
  outline: none !important;
  border-radius: 30px !important;
  height: 46px !important;
  padding: 8px !important;
  width: 118px !important;
  background: #231f20 !important;
  border: 1px solid #231f20 !important;
  color: #fff !important;
  float: right !important;
}
a.btn.btn-md.btn-primary:hover {
background: #f68d2d !important;
border: 1px solid #f68d2d !important;
text-decoration: none !important;
}
.cards {
  text-align: center;
}


/* card CSS */
.pirds {
  margin: 0;
  padding: 0;
width: 127px;
  height: 73px;
  padding: 0px;
  color: #fff;
  text-decoration: none !important;
  display: inline-flex;
flex-wrap: wrap;
  text-align: center;
  -webkit-border-radius: 23px;
  -moz-border-radius: 23px;
  border-radius: 23px;
margin-right: 3px;
  background: #2b85c6;
  background: -webkit-gradient(linear, 0 0, 0 bottom, from(#2b85c6), to(#1f69af));
  background: -webkit-linear-gradient(#2b85c6, #1f69af);
  background: -moz-linear-gradient(#2b85c6, #1f69af);
  background: -ms-linear-gradient(#2b85c6, #1f69af);
  background: -o-linear-gradient(#2b85c6, #1f69af);
  background: linear-gradient(#2b85c6, #1f69af);
}
.form-check.form-switch 
.pirds {
  display: flex;
margin: 0 auto;
}

.pirds2 {
  margin: 10px !important;
}

.rdcrdtop {
  width: 100%;
}
.rdcrdtop table {
  border: none;
  width: 100%;
  text-align: center;
}
.rdcrdtop tr {
  background: none !important;
}



.mws-table tbody td:first-child, .mws-table tfoot td:first-child {
  border-left: none;
}
.rdcrdtop table tr td {
  margin: 0;
  padding: 0;
  text-decoration: none !important;
  color: #fff;
  font-family: Arial, Helvetica, sans-serif !important;
  font-size: 12px;
  display: block;
  line-height: 18px;
  width: 100%;
  height: 50px;
  text-align: center;
  text-transform: uppercase;
  font-weight: normal;
  background: none !important;
  display: table-cell;
border-right-width:0px !important;
}

.rdcrd {
  margin: 0;
  padding: 0;
  text-decoration: none !important;
  color: #F3EB31;
  font-family: Arial, Helvetica, sans-serif !important;
  font-size: 12px;
  width: 100%;
  display: block;
  text-align: center;
  font-stretch: condensed;
  text-transform: uppercase;
}
@media (min-width: 1911px) and (max-width: 10000px){
  .container-fluid {
      width: 900px !important;
  }
    }